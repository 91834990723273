exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akce-js": () => import("./../../../src/pages/akce.js" /* webpackChunkName: "component---src-pages-akce-js" */),
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-chalupa-js": () => import("./../../../src/pages/chalupa.js" /* webpackChunkName: "component---src-pages-chalupa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nas-pribeh-js": () => import("./../../../src/pages/nas-pribeh.js" /* webpackChunkName: "component---src-pages-nas-pribeh-js" */),
  "component---src-pages-pokoje-js": () => import("./../../../src/pages/pokoje.js" /* webpackChunkName: "component---src-pages-pokoje-js" */),
  "component---src-pages-radosti-js": () => import("./../../../src/pages/radosti.js" /* webpackChunkName: "component---src-pages-radosti-js" */),
  "component---src-pages-ubytovaci-podminky-js": () => import("./../../../src/pages/ubytovaci-podminky.js" /* webpackChunkName: "component---src-pages-ubytovaci-podminky-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

